import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const PrivacyPage = () => (
  <Layout>
    <SEO title="お問い合わせ" />
    <h1>お問い合わせ</h1>
    <p>
      なにか気にある点や、要望等ございましたらお気軽にお問い合わせください。
    </p>
    <form name="contact" method="POST" data-netlify="true">
      <input type="hidden" name="form-name" value="contact" />
      {/* <p>
        <label>
          対象サービスを選択:
          <select name="apps[]">
            <option value="tabmemo">tabmemo</option>
          </select>
        </label>
      </p> */}
      <p>
        <label>お名前</label>
        <input type="text" name="name" />
      </p>
      <p>
        <label>返信先メールアドレス</label>
        <input type="email" name="email" />
      </p>
      <p>
        <label>問い合わせ内容</label>
        <textarea name="message" rows="5" />
      </p>
      <p>
        ※もしバグなどの報告の際に、スクリーンショットをご用意いただいている場合は、追って返信しますメールまで添付して返信をお願いします。
      </p>
      <p>
        送信後にボットで無いことの確認画面に遷移する場合がございます。その場合はチェックボックスにチェック後「submit」ボタンの押下をお願いします。
      </p>
      <p>
        <button type="submit">送信</button>
      </p>
    </form>
  </Layout>
)

export default PrivacyPage
